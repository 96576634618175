import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button, TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";

export default function LigaVereinAuswahlDialog({eventOperatorId, selectedVereinId, onAuswahl, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [filter, setFilter] = useState("");
    const [vereine, setVereine] = useState([]);

    const CELL_PADDING_VALUE = 10;

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/vereine/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setVereine(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const getSelectedVereinsName = () => {
        let verein = vereine.filter(tmp => tmp.id === selectedVereinId)[0];
        if( verein) {
            return verein.bezeichnung;
        }
        return "";
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredVereine;
        if( filter.length > 0 ) {
            filteredVereine = vereine.filter(tmp => tmp.bezeichnung.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredVereine = vereine;
        }
        filteredVereine = filteredVereine.filter(tmp => tmp.aktiv);
        filteredVereine.forEach(verein => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={verein.id}>
                <TableCell style={style}>
                    <Button onClick={() => onAuswahl(verein.id)}>Auswählen</Button>
                </TableCell>
                <TableCell style={style}>{verein.bezeichnung}</TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{fontSize:16}}>Aktueller Verein: {selectedVereinId ? getSelectedVereinsName() : "-"}</div>
            {selectedVereinId &&
                <div>
                    <Button onClick={() => onAuswahl(null)}>Ausgewählten Verein entfernen</Button>
                </div>
            }
            <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
            </div>
            <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
                <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100,}}>#</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Verein</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    }
    return <DHDialog onClose={() => onClose()} title="Verein auswählen" showCloseButton={true} comp={<>{getDataArea()}</>}/>
}
