import React from 'react'
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {getModusNameById} from "../../general/GameModusComponent";
import {TabPanel, TabView} from "primereact/tabview";
import {Sponsoren, Tabelle171er, Tabelle180er, TabelleBestDarts, TabelleHighfinishes, TabelleHighscores, TabelleLowDarts, TabelleWorstDarts, TournamentGameShortComponent} from "./ligaComponents";
import {Button} from "primereact/button";
import LigaTeilnehmerArea from "./LigaTeilnehmerArea";
import PropTypes from "prop-types";
import LigaZuschauenArea from "./LigaZuschauenArea";
import Turnierbaum from "../tournament/Turnierbaum";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import Chat from "../../general/Chat";
import ConfirmKennwortDialog from "../../general/ConfirmKennwortDialog";
import {Checkbox} from "primereact/checkbox";
import ConfirmWithQuestionDialog from "../../general/ConfirmWithQuestionDialog";
import {Dropdown} from "primereact/dropdown";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {NavLink} from "react-router-dom";
import SpielbarePhaseDialog from "./SpielbarePhaseDialog";
import LigaUrkunden from "./LigaUrkunden";
import LigaKalenderArea from "./LigaKalenderArea";
import TurnierVerwaltenDialog from "../tournament/TurnierVerwaltenDialog";
import KoDKOGameListe from "../tournament/KoDKOGameListe";
import TurnierQRCode from "./TurnierQRCode";
import RundUmsBoardDialog from "../veranstalter/board/RundUmsBoardDialog";
import PublicTournamentRanglisteTab from "../public/tournaments/PublicTournamentRanglisteTab";
import SoundComponent from "../../general/SoundComponent";
import TournamentPlatzierungTab from "./TournamentPlatzierungTab";
import PublicTournamentStatistikTab from "../public/tournaments/PublicTournamentStatistikTab";
import {PrimeIcons} from "primereact/api";
import LigaAktionenComponent from "../tournament/tabs/LigaAktionenComponent";
import {ProgressSpinner} from "primereact/progressspinner";
import TournamentAuslosungDialog from "../tournament/auslosung/TournamentAuslosungDialog";
import RefreshTournamentAdminAreaButtonComp from "../../RefreshTournamentAdminAreaButtonComp";
import RefreshCounterComp from "../../RefreshCounterComp";
import EventProtokollArea from "./EventProtokollArea";
import DHButtonDefault from "../../general/DHButtonDefault";
import PlayerRanglisteEditDialog from "./PlayerRanglisteEditDialog";

class TurnierDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {readonly: false, zeigeBeschreibungVoll: false, autorefresh: true,
            showKennwortDialog:false,kennwort:"", playSound: true, showAuslosungDialog: false,
            selectedDoppelpaarung: undefined, koTableView: false,
            showRanglisteEditDialog: false,
            showDeleteConfirmDialog: false, showSpielbarePhaseDialog: false, showEditDialog: false, showAktionen: false, showRundUmsBoardDialog: false};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
        this.getSpielerName = this.getSpielerName.bind(this);
        this.getUsernamesByUserId = this.getUsernamesByUserId.bind(this);
        this.isSpielerInaktiv = this.isSpielerInaktiv.bind(this);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    componentDidMount() {
        this.ladeDaten();
    }

    ladeDaten() {
        this.messages.clear();
        this.ladeTurnierDaten();
    }

    teilnahmeDoppelAendern() {
        this.messages.clear();
        let mussKennwortAbgefragtWerden = this.state.turnier.kennwort !== null && this.state.turnier.kennwort !== undefined && this.state.turnier.kennwort !== "";
        if( mussKennwortAbgefragtWerden && this.state.kennwort === "") {
            this.setState({showKennwortDialog:true});
            return
        }

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeDoppelAendern?tournamentid=" + this.state.turnier.id +
            "&doppelpaarungid=" + this.state.selectedDoppelpaarung.id + "&kennwort=" + this.state.kennwort,
            {},
            message => {
                if( message === "") {
                    this.showInfo("Speichern war erfolgreich!")
                    this.ladeDaten();
                } else {
                    this.showError(message);
                    this.setState({showKennwortDialog: false, kennwort:""});
                }
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            }
        );
    }

    teilnahmeAendern() {
        this.messages.clear();
        let mussKennwortAbgefragtWerden = this.state.turnier.kennwort !== null && this.state.turnier.kennwort !== undefined && this.state.turnier.kennwort !== "";
        if( mussKennwortAbgefragtWerden && this.state.kennwort === "") {
            this.setState({showKennwortDialog:true});
            return
        }

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeAendern?tournamentid=" + this.state.turnier.id + "&kennwort=" + this.state.kennwort,
            {},
            message => {
                if( message === "") {
                    this.showInfo("Speichern war erfolgreich!")
                    this.ladeDaten();
                } else {
                    this.showError(message);
                    this.setState({showKennwortDialog: false, kennwort:""});
                }
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            }
        );
    }

    teilnahmeBeenden() {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeAendern?tournamentid=" + this.state.turnier.id,
            {},
            json => {
                this.showInfo("Speichern war erfolgreich!")
                this.ladeDaten();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    ladeTurnierDaten() {
        if (this.props.turnierid === null || this.props.turnierid === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/ladekodko/" + this.props.turnierid;
        FetchUtil.fetchGet(url,
            json => {
                this.aktionNachLaden(json);
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der aktiven Ligen: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der aktiven Ligen: " + error.message);
            });
    }

    aktionNachLaden(json) {
        let readonly = json.tournamentDto.tournamentstatus === "abgeschlossen";
        this.setState({
            turnier: json.tournamentDto,
            setzliste: json.setzeintragListe,
            tournamentBeschreibungen: json.tournamentBeschreibungen,
            boardReihenfolge: json.boardReihenfolge,
            model: json.model,
            usernames: json.usernameDtos,
            userGames: json.userGamesDtos,
            doppelpaarungen: json.doppelpartnerDtos,
            tabelleUrkundeDtos:json.tabelleUrkundeDtos,
            ranglisteHighfinishes: json.ranglisteWrapper.ranglisteHighfinishes,
            ranglisteHighscores: json.ranglisteWrapper.ranglisteHighscores,
            rangliste180er: json.ranglisteWrapper.rangliste180er,
            rangliste171er: json.ranglisteWrapper.rangliste171er,
            bestDarts: json.ranglisteWrapper.bestDarts,
            worstDarts: json.ranglisteWrapper.worstDarts,
            lowDarts: json.ranglisteWrapper.lowDarts,
            statistik: json.ranglisteWrapper.statistikDto,
            playerStatistiks: json.ranglisteWrapper.playerStatistiks,
            boardGames: json.boardGames,
            readonly: readonly,
            showKennwortDialog: false,
            kennwort:""});
    }

    hasPlayersMeetingkeyAllowed(playerId1, playerId2) {
        if( this.state.turnier.doppelturnier) {
            return false;
        }
        if( playerId1 === undefined || playerId1 === null || playerId2 === undefined || playerId2 === null) {
            return false;
        }
        let player1 = this.state.turnier.tournamentPlayerEntities.find(player => player.id === playerId1);
        let player2 = this.state.turnier.tournamentPlayerEntities.find(player => player.id === playerId2);
        let usernameObject1 = this.getUsernamesByUserId(player1.userId);
        let usernameObject2 = this.getUsernamesByUserId(player2.userId);
        return usernameObject1.showMeetingkey && usernameObject2.showMeetingkey;
    }

    getUsernamesByUserId(userId) {
        for (const username of this.state.usernames) {
            if (username.userId === userId) {
                return username;
            }
        }
        return undefined;
    }

    isAktuellerUserAdministrator() {
        if( this.state.turnier.erstellerUserId === this.props.userId) {
            return true;
        }
        for (const admin of this.state.turnier.administratoren) {
            if (admin.userId === this.props.userId) {
                return true;
            }
        }
        return false;
    }

    isAktuellerUserInLigaVorhanden() {
        if (this.state.turnier.tournamentPlayerEntities === null || this.state.turnier.tournamentPlayerEntities === undefined) {
            return false;
        }

        if( this.state.turnier.doppelturnier) {
            for (const doppelDto of this.state.turnier.doppelpartnerDtos) {
                if( doppelDto.userId1 === this.props.userId || doppelDto.userId2 === this.props.userId) {
                    return true;
                }
            }

        } else {
            for (const player of this.state.turnier.tournamentPlayerEntities) {
                if (player.userId === this.props.userId && !player.inaktiv) {
                    return true;
                }
            }
        }
        return false;
    }

    getTurnierBetreiber() {
        for (const leagueoperator of this.props.leagueoperators) {
            if( leagueoperator.id === this.state.turnier.leagueOperatorId) {
                return leagueoperator;
            }
        }
        return undefined;
    }

    isSetModus() {
        let modusGewinnerseite = this.state.turnier.tournamentModusEntities.find(element => element.modusTyp === "gewinnerseite");
        return modusGewinnerseite.sets > 1;
    }

    getModusRow(key, bezeichner, modusElement) {
        if( this.state.turnier.gameType === "x01") {
            return <div key={key}>Modus: {bezeichner} GameType: X01 - Startpunkte: {modusElement.startpunkte} {modusElement.sets > 1 ? " - sets: " + modusElement.sets : ""} - ft legs {modusElement.firstToLegs} - Modus: {getModusNameById(modusElement.modusid)}</div>
        } else if( this.state.turnier.gameType === "cricket") {
            return <div key={key}>Modus: {bezeichner} GameType: Cricket - Startpunkte: {modusElement.startpunkte} {modusElement.sets > 1 ? " - sets: " + modusElement.sets : ""} - ft legs {modusElement.firstToLegs} - Zufallszahlen: {modusElement.zufallszahlen ? "ja" : "nein"} - Cutthroat: {modusElement.cutthroat ? "ja" : "nein"}</div>
        } else {
            return "ADMIN VERSTÄNDIGEN - Screenshot";
        }
    }

    getTurnierDetailArea() {
        if (this.state.turnier === null || this.state.turnier === undefined) {
            return <div>Es sind noch keine Daten geladen...</div>
        }

        let modusArray = [];
        let turnier = this.state.turnier;
        if (turnier.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id || turnier.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
            let modusGewinnerseite = turnier.tournamentModusEntities.find(element => element.modusTyp === "gewinnerseite");
            let modusVerliererseite = turnier.tournamentModusEntities.find(element => element.modusTyp === "verliererseite");
            let modusAchtelfinale = turnier.tournamentModusEntities.find(element => element.modusTyp === "achtelfinale");
            let modusViertelfinale = turnier.tournamentModusEntities.find(element => element.modusTyp === "viertelfinale");
            let modusHalbfinale = turnier.tournamentModusEntities.find(element => element.modusTyp === "halbfinale");
            let modusFinale = turnier.tournamentModusEntities.find(element => element.modusTyp === "finale");
            if( modusGewinnerseite !== null && modusGewinnerseite !== undefined) {
                let bezeichner = "";
                if( turnier.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id ) {
                    bezeichner = "Gewinnerseite:";
                }
                modusArray.push(this.getModusRow("modus_gewinnerseite", bezeichner, modusGewinnerseite));
            }
            if( modusVerliererseite !== null && modusVerliererseite !== undefined) {
                modusArray.push(this.getModusRow("modus_verliererseite", "Verliererseite:", modusVerliererseite));
            }
            if( modusAchtelfinale !== null && modusAchtelfinale !== undefined) {
                modusArray.push(this.getModusRow("modus_achtelfinale", "Achtelfinale:", modusAchtelfinale));
            }
            if( modusViertelfinale !== null && modusViertelfinale !== undefined) {
                modusArray.push(this.getModusRow("modus_viertelfinale", "Viertelfinale:", modusViertelfinale));
            }
            if( modusHalbfinale !== null && modusHalbfinale !== undefined) {
                modusArray.push(this.getModusRow("modus_halbfinale", "Halbfinale:", modusHalbfinale));
            }
            if( modusFinale !== null && modusFinale !== undefined) {
                modusArray.push(this.getModusRow("modus_finale", "Finale:", modusFinale));
            }
        }

        let ligaBetreiber = this.getTurnierBetreiber();
        let ligaBetreiberName = "";
        let ligaBetreiberHomepage = "";
        let ligaBetreiberSocialmedia = "";
        if( ligaBetreiber !== undefined) {
            ligaBetreiberName = ligaBetreiber.name + " / " + ligaBetreiber.shortname;
            ligaBetreiberHomepage = ligaBetreiber.homepage;
            ligaBetreiberSocialmedia = ligaBetreiber.socialmedia;
        }

        let fixedColumWidth = 140;
        return <div style={{textAlign: "left"}}>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Bezeichnung:</div>
                        <div className="col">
                            {this.state.turnier.kennwort !== null && this.state.turnier.kennwort !== undefined && this.state.turnier.kennwort !== "" && <img src="/images/key.png" alt="Kennwortgeschützt" style={{marginRight:5, width:20}}/>}
                            {this.state.turnier.bezeichnung}
                        </div>
                    </div>
                    {this.state.turnier.tournamentSponsorenEntities.length > 0 && <Sponsoren sponsoren={this.state.turnier.tournamentSponsorenEntities} />}
                    {ligaBetreiberName !== "" && <>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierbetreiber:</div>
                            <div className="col">{ligaBetreiberName}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}></div>
                            <div className="col">
                                {ligaBetreiberHomepage ? <a href={ligaBetreiberHomepage} target="_blank" rel="noopener noreferrer" style={{color: "white"}}><img src="/images/homepage.png" alt="Homepage" style={{width:30}}/></a> :"" }
                                {ligaBetreiberSocialmedia ? <a href={ligaBetreiberSocialmedia} target="_blank" rel="noopener noreferrer" style={{color: "white"}}><img src="/images/facebook.png" alt="Facebook" style={{marginLeft:10, width:30}}/></a> :"" }
                            </div>
                        </div>
                    </>
                    }
                    {this.state.turnier.anmeldeende !== null && this.state.turnier.anmeldeende !== undefined &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Anmeldeende:</div>
                            <div className="col">{this.state.turnier.anmeldeendeMitUhrzeitString}</div>
                        </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Startzeitpunkt:</div>
                        <div className="col">{this.state.turnier.beginnMitUhrzeitString}</div>
                    </div>
                    {this.getBeschreibungArea(fixedColumWidth)}
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Ersteller:</div>
                        <div className="col">{this.state.turnier.erstellerName}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierleitung:</div>
                        <div className="col">{this.getAdministratoren()}</div>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierstatus:</div>
                        <div className="col">{this.state.turnier.tournamentstatus}</div>
                    </div>
                    <div className="grid">
                        <div className="col">
                            {modusArray}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Finale mit Satzausgleich:</div>
                        <div className="col">{this.state.turnier.finaleVerliererseiteMussZweiSpieleGewinnen ? "Ja" : "Nein"}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Doppelturnier:</div>
                        <div className="col">{this.state.turnier.doppelturnier ? "Ja" : "Nein"}{this.state.turnier.dyp && " - DYP"}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Ranglistenturnier:</div>
                        <div className="col">{this.state.turnier.rangliste ? "Ja" : "Nein"}</div>
                    </div>
                    {(this.state.turnier.averageObergrenze !== null && this.state.turnier.averageObergrenze !== undefined) &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Avg. Obergrenze:</div>
                            <div
                                className="col">{this.state.turnier.averageObergrenze}</div>
                        </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Anzahl Spieler:</div>
                        <div
                            className="col">{this.state.turnier.tournamentPlayerEntities !== null && this.state.turnier.tournamentPlayerEntities !== undefined ? this.state.turnier.tournamentPlayerEntities.length : "0"}</div>
                    </div>
                    {(this.state.turnier.minAnzahlTeilnehmer !== null && this.state.turnier.minAnzahlTeilnehmer !== undefined) &&
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Min. Anzahl Teilnehmer:</div>
                        <div
                            className="col">{this.state.turnier.minAnzahlTeilnehmer}</div>
                    </div>
                    }
                    {(this.state.turnier.maxAnzahlTeilnehmer !== null && this.state.turnier.maxAnzahlTeilnehmer !== undefined) &&
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Max. Anzahl Teilnehmer:</div>
                        <div
                            className="col">{this.state.turnier.maxAnzahlTeilnehmer}</div>
                    </div>
                    }
                </div>
            </div>
            {this.state.turnier.tournamentstatus === "neu" && this.state.turnier.online && this.state.turnier.doppelturnier && !this.state.turnier.dyp && !this.isAktuellerUserInLigaVorhanden() &&
                <>
                <div className="grid" style={{fontSize:12, marginBottom:15}}>
                    Hier kannst du deinen Doppelnamen auswählen und damit am Turnier teilnehmen. Sofern du hier keinen Doppelnamen findest ist für dich noch keine Doppelpaarung angelegt, im Profil kannst du diese anlegen/verwalten oder Details sehen.
                </div>
                <div className="grid">
                        <Dropdown value={this.state.selectedDoppelpaarung} options={this.state.doppelpaarungen}
                                  optionLabel="name"
                                  onChange={(e) => {
                                      this.setState({selectedDoppelpaarung: e.value})
                                  }}
                                  placeholder="Eingabe..." style={{width: 300}}/>
                        <Button label="Am Doppelturnier teilnehmen" icon="pi pi-plus"
                                onClick={() => this.teilnahmeDoppelAendern()}
                                disabled={this.state.selectedDoppelpaarung === undefined || this.state.selectedDoppelpaarung === null}
                                style={{height: 40, width: 350, marginLeft: 10, marginRight: 10}}/>
                </div>
                </>
            }
            <div className="grid" style={{marginTop:10}}>
                {this.state.turnier.tournamentstatus === "neu" && !this.state.turnier.doppelturnier && !this.isAktuellerUserInLigaVorhanden() && this.state.turnier.online &&
                <Button label="Am Turnier teilnehmen" icon="pi pi-plus" onClick={() => this.teilnahmeAendern()}
                        style={{height: 40, width: 350, marginBottom:5, marginRight: 5}}/>
                }
                {this.state.turnier.tournamentstatus === "neu" && this.isAktuellerUserInLigaVorhanden() && this.state.turnier.online &&
                <Button label="Aus Turnier austreten, nicht teilnehmen" icon="pi pi-minus"
                        onClick={() => this.teilnahmeBeenden()}
                        style={{height: 40, width: 350, marginBottom:5, marginRight: 5}}/>
                }
                {this.state.turnier.tournamentstatus === "gestartet" && this.isAktuellerUserInLigaVorhanden() &&
                <Button label="Teilnahme beenden" icon="pi pi-plus" onClick={() => this.setState({showDeleteConfirmDialog: true})}
                        style={{height: 40, width: 350, marginBottom:5, marginRight: 5}}/>
                }
                {this.state.turnier.tournamentstatus === "neu" && this.isAktuellerUserAdministrator() &&
                    <Button label="Turnier starten" icon="pi pi-align-justify"
                            onClick={() => this.setState({showAuslosungDialog: true})}
                            style={{height: 40, width: 200, marginBottom:5, marginRight: 5}}/>
                }
                {(this.isAktuellerUserAdministrator() && !this.state.readonly && this.state.turnier.online) &&
                    <Button label="KO/DKO Spielbare Phase" icon="pi pi-unlock"
                            onClick={() => this.setState({showSpielbarePhaseDialog: true})}
                            style={{height: 40, width: 300, marginBottom:5, marginRight: 5}}/>
                }
                {this.isAktuellerUserAdministrator() &&
                    <Button label="Bearbeiten" icon="pi pi-pencil"
                            onClick={() => this.setState({showEditDialog: true})}
                            style={{height: 40, width: 150, marginBottom:5, marginRight: 5}}/>
                }
                {this.isAktuellerUserAdministrator() &&
                    <Button label="Aktionen" icon={PrimeIcons.BOLT}
                            onClick={() => this.setState({showAktionen: true})}
                            style={{height: 40, width: 150, marginBottom:5, marginRight: 5}}/>
                }
                {this.state.turnier.tournamentstatus !== "abgeschlossen" && !this.state.turnier.online && this.isAktuellerUserAdministrator() &&
                    <Button label="Board/Schreiber verwalten" icon="pi pi-book" onClick={() => this.setState({showRundUmsBoardDialog: true})}
                            style={{height: 40, marginBottom:5, marginRight: 5}}/>
                }
            </div>
        </div>
    }

    getHightlightsArea() {
        return <div className="grid">
            <Tabelle180er rangliste180er={this.state.rangliste180er} getSpielerName={this.getSpielerName}/>
            <Tabelle171er rangliste171er={this.state.rangliste171er} getSpielerName={this.getSpielerName}/>
            <TabelleHighfinishes ranglisteHighfinishes={this.state.ranglisteHighfinishes} getSpielerName={this.getSpielerName}/>
            <TabelleHighscores ranglisteHighscores={this.state.ranglisteHighscores} getSpielerName={this.getSpielerName}/>
            <TabelleLowDarts lowDarts={this.state.lowDarts}  getSpielerName={this.getSpielerName} />
            <TabelleBestDarts bestDarts={this.state.bestDarts}  getSpielerName={this.getSpielerName} />
            <TabelleWorstDarts worstDarts={this.state.worstDarts}  getSpielerName={this.getSpielerName} />
        </div>;
    }

    getSpielerName(id) {
        if (id === undefined) {
            return "";
        }

        for (let i = 0; i < this.state.turnier.tournamentPlayerEntities.length; i++) {
            let spieler = this.state.turnier.tournamentPlayerEntities[i];
            if (spieler.id === id || spieler.userId === id || spieler.doppelpartnerId === id) {
                return spieler.name;
            }
        }
        return "Freilos";
    }

    getGamesBySpieltag(spieltag) {
        let liga = this.state.turnier
        let gruppenEintraege = liga.tournamentGroupGameEntities;
        let games = []
        if (gruppenEintraege === null || gruppenEintraege === undefined) {
            return games;
        }
        for (const eintrag of gruppenEintraege) {
            if (eintrag.spielrunde === spieltag) {
                games.push(eintrag);
            }
        }
        return games;

    }

    isSpielerInaktiv(spielerid) {
        for (const player of this.state.turnier.tournamentPlayerEntities) {
            if (player.id === spielerid && player.inaktiv) {
                return true;
            }
        }
        return false;
    }

    getStatistikArea() {
        return <div style={{backgroundColor: "#022836", color:"white", textAlign:"left", padding:5}}>
            <PublicTournamentStatistikTab tournamentId={this.state.turnier.id} participants={this.state.turnier.tournamentPlayerEntities}
                                          gesamtStatistik={this.state.statistik}
                                          playerStatistiks={this.state.playerStatistiks}/>
        </div>
    }

    getHeader() {
        return <div className="pageHeader" style={{backgroundColor: "#b3ccff"}}>
                Turnier - Details
                {this.state.turnier && !this.state.turnier.online ?
                    <RefreshTournamentAdminAreaButtonComp tournamentId={this.props.turnierid} text="Auto" autorefresh={this.state.autorefresh} onRefresh={() => this.ladeDaten()} shutdownminutes={600}
                                                          onAutoRefresh={(schalter) => {
                                                              this.setState({autorefresh: schalter});
                                                          }} onData={(json) => this.aktionNachLaden(json)}/>
                    :
                    <RefreshCounterComp text="Auto-Refresh" onRefresh={() => this.ladeDaten()}/>
                }
            </div>
    }

    render() {
        let turnier = this.state.turnier
        if (turnier === null || turnier === undefined) {
            return <div>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                {this.getHeader()}
                <ProgressSpinner style={{width:100, height:100}}/>
            </div>
        }

        let isTurnierRunning = turnier.tournamentstatus !== "neu";
        let isTurnierAbgeschlossen = turnier.tournamentstatus === "abgeschlossen";
        let isUserInTurnierRelevant = this.isAktuellerUserInLigaVorhanden() || this.isAktuellerUserAdministrator();

        let tabs = [];
        if( isTurnierAbgeschlossen && (turnier.rangliste || (turnier.ranglisteId !== null && turnier.ranglisteId !== undefined))) {
            tabs.push(<TabPanel header="Rangliste" key="tab_rangliste">
                    {this.getRanglisteArea()}
                </TabPanel>
            );
        }
        if( isTurnierRunning ) {
            tabs.push(<TabPanel header="Platzierung" key="tab_platzierung">
                    {this.getPlatzierungArea()}
                </TabPanel>
            );
        }

        if( isTurnierAbgeschlossen && turnier.urkunden) {
            tabs.push(<TabPanel header="Urkunden" key="tab_urkunden">
                    <LigaUrkunden tournamentId={turnier.id}
                                  tabelleUrkundeDtos={this.state.tabelleUrkundeDtos}
                                  rangliste171er={this.state.rangliste171er}
                                  rangliste180er={this.state.rangliste180er}
                                  ranglisteHighscores={ this.state.ranglisteHighscores}
                                  ranglisteHighfinishes={ this.state.ranglisteHighfinishes}
                                  ranglisteBestDarts={this.state.bestDarts}
                                  callbackGetSpielername={this.getSpielerName}/>
                </TabPanel>
            );
        }

        if( this.isAktuellerUserAdministrator() && turnier.online) {
            tabs.push(<TabPanel header="Games" key="tab_games">
                    {this.getAdminGamesTab()}
                </TabPanel>
            );
        }
        if( this.isAktuellerUserAdministrator()) {
            tabs.push(<TabPanel header="Protokoll" key="tab_protokoll">
                <EventProtokollArea tournamentId={turnier.id}/>
            </TabPanel>);
        }
        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            {this.getHeader()}
            <div style={{paddingRight: 20, paddingLeft: 20}}>
                {this.getTurnierDetailArea()}
            </div>

            {this.getGamesArea()}

            <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10, border:"none"}}>
                <TabPanel header="Turnierbaum" disabled={!isTurnierRunning}>
                    <div>
                        {!this.state.koTableView &&
                            <div>
                                <div style={{textAlign:"left", marginBottom:10}}>
                                    <Button icon="pi pi-list"
                                            onClick={() => this.setState({koTableView: !this.state.koTableView})}
                                            style={{marginRight: 10}}/>
                                </div>
                                <Turnierbaum userId={this.props.userId}
                                             turnier={this.state.turnier}
                                             tournamentGroup={1}
                                             userGames={this.state.userGames}
                                             model={this.state.model}
                                             readonly={!this.isAktuellerUserAdministrator() || this.state.turnier.tournamentstatus === "abgeschlossen"}
                                             boardGames={this.state.boardGames}
                                             tournamentBeschreibungen={this.state.tournamentBeschreibungen}
                                             loadData={this.ladeDaten}
                                />
                            </div>
                        }
                        {this.state.koTableView &&
                            <KoDKOGameListe tournamentPlayerEntities={this.state.turnier.tournamentPlayerEntities}
                                            scoringWithDarthelfer={this.state.turnier.scoringWithDarthelfer}
                                            tournamentBeschreibungen={this.state.tournamentBeschreibungen}
                                            ergebnisse={this.state.turnier.tournamentErgebnisEntities}
                                            tournamentId={this.state.turnier.id}
                                            tournamentGroup={1}
                                            eventOperatorId={this.state.turnier.eventOperatorId}
                                            online={this.state.turnier.online}
                                            boardGames={this.state.boardGames}
                                            finaleMitSatzausgleich={this.state.turnier.finaleVerliererseiteMussZweiSpieleGewinnen != null && this.state.turnier.finaleVerliererseiteMussZweiSpieleGewinnen}
                                            models={this.state.model}
                                            onRefresh={this.ladeDaten}
                                            readonly={!this.isAktuellerUserAdministrator() || this.state.turnier.tournamentstatus === "abgeschlossen"}
                                            boardReihenfolge={this.state.boardReihenfolge} onChangeView={() => this.setState({koTableView: !this.state.koTableView})}/>
                        }

                    </div>
                </TabPanel>
                <TabPanel header="Highlights" disabled={!isTurnierRunning || this.state.turnier.gameType !== "x01"}>
                    {this.getHightlightsArea()}
                </TabPanel>
                <TabPanel header="Teilnehmer">
                    {this.getTeilnehmerArea()}
                </TabPanel>
                <TabPanel header="Statistik" disabled={!isTurnierRunning || this.state.turnier.gameType !== "x01"}>
                    {this.getStatistikArea()}
                </TabPanel>
                { this.state.turnier.online &&
                    <TabPanel header="Terminabsprache" disabled={this.state.turnier.doppelturnier || !isTurnierRunning || isTurnierAbgeschlossen || !isUserInTurnierRelevant}>
                        {<LigaKalenderArea liga={this.state.turnier} userId={this.props.userId}/>}
                    </TabPanel>
                }
                <TabPanel header="Zuschauen" disabled={!isTurnierRunning || isTurnierAbgeschlossen || this.state.turnier.gameType !== "x01"}>
                    {this.getZuschauenArea()}
                </TabPanel>
                {this.state.turnier.online &&
                    <TabPanel header="Chat"
                              disabled={!isTurnierRunning || isTurnierAbgeschlossen || !isUserInTurnierRelevant}>
                        {this.getChat()}
                    </TabPanel>
                }
                {turnier.eventOperatorId !== null && turnier.eventOperatorId !== undefined &&
                    <TabPanel header="Links">
                        <TurnierQRCode eventOperatorId={turnier.eventOperatorId} tournamentId={turnier.id} tournamentSystem={turnier.tournamentstatus} onlineTournament={turnier.online} bezeichnung={turnier.bezeichnung}/>
                    </TabPanel>
                }
                {tabs}
            </TabView>
            {this.state.showRundUmsBoardDialog && <RundUmsBoardDialog eventOperatorId={turnier.eventOperatorId} onClose={() => this.setState({showRundUmsBoardDialog: false})} />}
            {this.state.showSpielbarePhaseDialog && <SpielbarePhaseDialog tournamentId={turnier.id} onClose={() => this.setState({showSpielbarePhaseDialog: false})} visible={this.state.showSpielbarePhaseDialog}/>}
            {this.state.showEditDialog && <TurnierVerwaltenDialog tournamentId={turnier.id}
                                                                   callBackRefresh={this.ladeDaten}
                                                                   callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                                   callBackOnClose={() => this.setState({showEditDialog:false})}/>
            }
            {this.state.showAktionen && <LigaAktionenComponent showAsSidebar={true} onError={(message) => this.showError(message)}
                                                               onInfo={(message) => this.showInfo(message)}
                                                               onClearMessages={() => this.messages.clear()}
                                                               callBackRefresh={this.ladeDaten}
                                                               callBackCloseDialog={() => console.log("callBackCloseDialog nicht unterstützt")}
                                                               proTournament={this.state.turnier}
                                                               onClose={() => this.setState({showAktionen: false})}
                                                               callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                               callBackLoadNewTournament={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
            />}
            <ConfirmKennwortDialog visible={this.state.showKennwortDialog}
                                   header="Turnierkennwort eingeben."
                                   textOben="Der Turnierbetreiber erwartet ein Kennwort zur Teilnahme."
                                   changeKennwort={(kennwort) => this.setState({kennwort: kennwort})}
                                   callBackOnAbbrechen={() => this.setState({showKennwortDialog:false})}
                                   callBackOnAbsenden={() => this.state.turnier.doppelturnier ? this.teilnahmeDoppelAendern() : this.teilnahmeAendern()}/>
            {this.state.showAuslosungDialog && <TournamentAuslosungDialog tournament={this.state.turnier}
                                                                          onClose={() => this.setState({showAuslosungDialog: false})}
                                                                          onRefresh={() => this.ladeDaten()}/>
            }
            <ConfirmWithQuestionDialog visible={this.state.showDeleteConfirmDialog}
                                       callBackOnNein={() => this.setState({
                                           showDeleteConfirmDialog: false
                                       })}
                                       callBackOnJa={() => this.teilnahmeNachTurnierstartBeenden()}
                                       header="Teilnehmer am Turnier beenden"
                                       textOben="Das Turnier läuft bereits, du solltest nicht ohne sehr wichtigen Grund aus dem Turnier austreten. Sofern du austreten möchtest, schreibe den Grund bitte noch in den Chat."
                                       textUnten="Möchtest du wirklich deine Teilnahme beenden?"/>
            {this.state.showRanglisteEditDialog && <PlayerRanglisteEditDialog
                onRefresh={() => this.ladeDaten()}
                onClose={() => this.setState({showRanglisteEditDialog: false})}
                players={this.state.turnier.tournamentPlayerEntities}/> }
        </div>
    }

    getAdministratoren() {
        if (this.state.turnier === null || this.state.turnier === undefined || this.state.turnier.administratoren === null || this.state.turnier.administratoren === undefined) {
            return "";
        }

        let administratoren = "";
        for (const administrator of this.state.turnier.administratoren) {
            if( administratoren !== "") {
                administratoren += " - "
            }
            administratoren += administrator.name;
        }
        return administratoren;
    }

    getTeilnehmerArea() {
        return <LigaTeilnehmerArea liga={this.state.turnier}
                                   players={this.state.turnier.tournamentPlayerEntities}
                                   showEditButton={true}
                                   readonly={this.state.readonly}
                                   getUsernamesByUserId={this.getUsernamesByUserId}
                                   callBackRefresh={this.ladeDaten}
                                   isVerschiebenErlaubt={false}
                                   showUsernames={!this.state.turnier.doppelturnier}
                                   isAdmin={this.isAktuellerUserAdministrator()}/>
    }

    getAdminGamesTab() {
        if( this.state.turnier.doppelturnier) {
            return "Wird in Doppelturnieren nicht unterstützt.";
        }

        let games = [];
        for (const game of this.state.turnier.games) {
            if( game.abgeschlossen ) {
                continue;
            }
            let urlVideo = NAVIGATION_ITEM.GAME_VIDEO.route + "?gameid=" + game.id + "&username=player&testhinweis=false";
            let urlGameWatch = NAVIGATION_ITEM.GAMEWATCH.route + "?gameid=" + game.id;

            let showMeetingKeyAllowed = this.hasPlayersMeetingkeyAllowed(game.player1id, game.player2id);
            games.push(<div className="grid">
                <div className="col">
                    {this.getSpielerName(game.user1id)} : {this.getSpielerName(game.user2id)} - Meetingkey:
                        {showMeetingKeyAllowed ? <>
                            {game.meetingKey}
                                <NavLink to={urlVideo} style={{textDecoration: "none"}}>
                                    <img src="/images/dart_select.png" alt="Watch Scoring" style={{cursor: "pointer", width: 30, marginLeft: 5}}/>
                                </NavLink>
                                <NavLink to={urlGameWatch} style={{textDecoration: "none"}}>
                                    <img src="/images/webcam_color.png" alt="Watch Video" style={{cursor: "pointer", width: 30, marginLeft: 5}}/>
                                </NavLink>
                    </> : "Anzeige nicht erlaubt!!!"}
                </div>
            </div>);
        }
        return <div className="grid" style={{padding:20, textAlign:"left"}}>
            <div className="col">
                <div className="grid" style={{marginBottom:50}}>
                    <div className="col">
                        Hier bekommen Liga- und Turnierleiter alle aktuell vorhandenen und nicht abgeschlossenen Spiele angezeigt.<br/>Hintergrund ist, dass evtl.Meetingkeys zum Beobachten von Spiele bzw. Streaming freigegeben wurden.
                    </div>
                </div>
                {games}
            </div>
        </div>
    }

    getRanglisteArea() {
        return <div style={{backgroundColor: "#022836", padding:10}}>
            {this.isAktuellerUserAdministrator() && <div style={{paddingTop:30, paddingBottom:30, textAlign:"left", alignContent:"left"}}>
                <DHButtonDefault label="Rangliste bearbeiten" icon={undefined} disabled={false} onClick={() => this.setState({showRanglisteEditDialog: true})}/>
            </div>}
            <PublicTournamentRanglisteTab participants={this.state.turnier.ranglistePlayerEntities}
                                          eventOperatorId={this.state.turnier.eventOperatorId}
                                          rankingId={this.state.turnier.ranglisteId}/>
        </div>
    }

    getPlatzierungArea() {
        return <div style={{backgroundColor: "#022836", padding:10}}>
            <TournamentPlatzierungTab tournamentId={this.state.turnier.id}/>
        </div>
    }

    getZuschauenArea() {
        return <LigaZuschauenArea tournamentId={this.state.turnier.id}/>
    }

    getChat() {
        return <Chat type="turnier" loadData={true} id={this.state.turnier.id} userId={this.props.userId}
                      callBackOnError={(message) => this.showError(message)}/>
    }

    getBeschreibungArea(fixedColumWidth) {
        if (this.state.turnier.beschreibung === null || this.state.turnier.beschreibung === undefined) {
            return "";
        }
        let beschreibungGekuerzt = false;
        let beschreibung;
        if (this.state.zeigeBeschreibungVoll || this.state.turnier.beschreibung.length <= 50) {
            beschreibung = this.state.turnier.beschreibung;
        } else {
            beschreibung = this.state.turnier.beschreibung.substring(0, 50);
            beschreibungGekuerzt = true;
        }
        return <div className="grid">
            <div className="col-fixed" style={{width: fixedColumWidth}}>Beschreibung:</div>
            <div className="col" style={{whiteSpace: "pre-line", cursor: "pointer"}}
                 onClick={() => this.setState({zeigeBeschreibungVoll: !this.state.zeigeBeschreibungVoll})}>
                {beschreibung}{beschreibungGekuerzt ? <span style={{color:"blue"}}><br/>... mehr Anzeigen ...</span> : ""}
            </div>
        </div>
    }

    getGamesArea() {
        if( this.state.userGames === null || this.state.userGames === undefined || this.state.userGames.length === 0) {
            return "";
        }
        let cols = [];
        for (const userGame of this.state.userGames) {
            cols.push(<div className="col" key={"key_mygame_" + userGame.userGegnerId} style={{textAlign:"left", marginLeft:20}}>
                <TournamentGameShortComponent userGame={userGame}/>
            </div>)
        }

        return <div style={{marginTop:20, padding:20}}>
                <div className="grid"><div className="col" style={{textAlign: "left"}}>Deine aktuell anstehende Spiele, bitte gehe umgehend in dein aktuelles Spiel:</div></div>
                <div className="grid">
                    <div className="col" style={{textAlign: "left"}}>
                    <Checkbox onChange={e => this.setState({playSound: !this.state.playSound})}
                              checked={this.state.playSound}></Checkbox>
                    <span style={{marginLeft:5}}>Spiele Sound sobald ein Game für mich vorhanden</span>
                        {(this.state.playSound && cols.length > 0) && <SoundComponent loop={true}
                                                                                file={"180.wav"}
                                                                                onEnded={() => this.setState({soundValue: undefined})}/>
                        }
                    </div>
                </div>
            {cols.length === 0 && <div style={{textAlign: "left"}}>Aktuell sind keine Spiele für dich vorhanden</div>}
            {cols.length !== 0 && <div className="grid" style={{marginTop:10}}>{cols}</div>}
        </div>
    }

    teilnahmeNachTurnierstartBeenden() {
        this.messages.clear();
        let tournamentid = this.state.turnier.id;
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeNachTurnierstartBeenden?tournamentid=" + tournamentid;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.showInfo("Teilnahme wurde entfernt");
                this.setState({showDeleteConfirmDialog: false});
                this.ladeDaten()
            },
            responseNotOk => {
                this.showError("Fehler beim Entfernen eines Teilnehmers: " + responseNotOk.message);
                this.setState({showDeleteConfirmDialog: false});
            },
            error => {
                this.showError("Fehler beim Entfernen eines Teilnehmers: " + error.message);
                this.setState({showDeleteConfirmDialog: false});
            }
        );
    }
}

TurnierDetail.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default TurnierDetail
