import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button, TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DateUtil from "../../../util/DateUtil";
import ConfirmDialog from "../../general/ConfirmDialog";

export default function LigaSpielerAuswahlDialog({eventOperatorId, mannschaftId, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [filter, setFilter] = useState("");
    const [spielerZugeordnet, setSpielerZugeordnet] = useState([]);
    const [spielerNichtZugeordnet, setSpielerNichtZugeordnet] = useState([]);
    const [selectedSpieler, setSelectedSpieler] = useState([]);

    const [selectedLoeschenSpielerId, setSelectedLoeschenSpielerId] = useState(undefined);
    const [selectedZuordnenSpielerId, setSelectedZuordnenSpielerId] = useState(undefined);

    const CELL_PADDING_VALUE = 10;

    useEffect(() => {
        ladeSpielerZurMannschaft();
        ladeSpielerZumEventOperator()
    }, [mannschaftId]);

    const ladeSpielerZurMannschaft = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spieler/mannschaft/" + mannschaftId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setSelectedSpieler(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const ladeSpielerZumEventOperator = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spieler/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                let listeBereitsZugeordnet = json.filter(tmp => tmp.aktiv && tmp.ligaMannschaftId);
                setSpielerZugeordnet(listeBereitsZugeordnet);
                let listeNichtZugeordnet = json.filter(tmp => tmp.aktiv && !tmp.ligaMannschaftId);
                setSpielerNichtZugeordnet(listeNichtZugeordnet);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const zuordnen = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spieler/zuweisen/mannschaft/" + selectedZuordnenSpielerId + "/" + mannschaftId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                ladeSpielerZurMannschaft();
                ladeSpielerZumEventOperator();
                setSelectedZuordnenSpielerId(undefined);
                onRefresh();
                setInfoMessage("Spieler wurde zugewiesen.");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedZuordnenSpielerId(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setSelectedZuordnenSpielerId(undefined);
            });
    }

    const loeschen = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spieler/zuweisen/mannschaft/" + selectedLoeschenSpielerId + "/" + null;
        FetchUtil.fetchPost(url,
            {},
            json => {
                ladeSpielerZurMannschaft();
                ladeSpielerZumEventOperator();
                setSelectedLoeschenSpielerId(undefined);
                onRefresh();
                setInfoMessage("Spieler wurde zugewiesen.");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedLoeschenSpielerId(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedLoeschenSpielerId(undefined);
            });
    }

    const getTableRows = (propSpieler, showAuswaehlen, showLoeschen) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredSpieler;
        if( filter.length > 0 ) {
            filteredSpieler = propSpieler.filter(tmp => tmp.spielernummer.toUpperCase().includes(filter.toUpperCase()) ||
                tmp.name.toUpperCase().includes(filter.toUpperCase()) ||
                tmp.vorname.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredSpieler = propSpieler;
        }
        filteredSpieler = filteredSpieler.filter(tmp => tmp.aktiv);
        filteredSpieler.forEach(tmpSpieler => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={tmpSpieler.id}>
                {(showAuswaehlen || showLoeschen) &&
                    <TableCell style={style}>
                        {showAuswaehlen && <Button onClick={() => setSelectedZuordnenSpielerId(tmpSpieler.id)}>Auswählen</Button>}
                        {showLoeschen && <Button onClick={() => setSelectedLoeschenSpielerId(tmpSpieler.id)}>Löschen</Button>}
                    </TableCell>
                }
                <TableCell style={style}>{tmpSpieler.spielernummer}</TableCell>
                <TableCell style={style}>{tmpSpieler.name}</TableCell>
                <TableCell style={style}>{tmpSpieler.vorname}</TableCell>
                <TableCell style={style}>{DateUtil.localDateToString(tmpSpieler.geburtstag)}</TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
            </div>
            <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                Hinweis: Hier werden dir aktive Spieler angezeigt die noch keiner Mannschaft zugewiesen sind.
            </div>
            <div style={{fontWeight: "bold", marginTop: 20}}>Der Mannschaft zugeordnet</div>
            <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
                <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100,}}>#</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 120}}>Nummer</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Name</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Vorname</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100}}>Geburtstag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTableRows(selectedSpieler, false, true)}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{fontWeight: "bold", marginTop: 20}}>Keiner Mannschaft zugeordnet</div>
            <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
                <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100,}}>#</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 120}}>Nummer</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Name</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Vorname</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100}}>Geburtstag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTableRows(spielerNichtZugeordnet, true, false)}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{fontWeight: "bold", marginTop: 20}}>Bereits einer Mannschaft zugeordnet</div>
            <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
                <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 120}}>Nummer</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Name</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Vorname</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100}}>Geburtstag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTableRows(spielerZugeordnet, false, false)}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedZuordnenSpielerId && <ConfirmDialog header="Zuordnen...." textUnten="Möchtest du den Spieler wirklich der Mannschaft zuordnen?" visible={true} callBackOnNein={() => setSelectedZuordnenSpielerId(undefined)} callBackOnJa={() => zuordnen()}/>}
            {selectedLoeschenSpielerId && <ConfirmDialog header="Löschen...." textUnten="Spielerzuordnung zur Mannschaft wirklich löschen?" visible={true} callBackOnNein={() => setSelectedLoeschenSpielerId(undefined)} callBackOnJa={() => loeschen()}/>}
        </div>
    }
    return <DHDialog onClose={() => onClose()} title="Spieler auswählen" showCloseButton={true} comp={<>{getDataArea()}</>}/>
}
