import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";
import DHButtonSave from "../../general/DHButtonSave";
import DHNumberTextField from "../../general/DHNumberTextField";
import DHSelect from "../../general/DHSelect";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";

export default function PlayerRanglisteEditDialog({players, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [working, setWorking] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState(undefined);
    const [anwesenheit, setAnwesenheit] = useState(0);
    const [bestleistung, setBestleistung] = useState(0);
    const [rangliste, setRangliste] = useState(0);

    useEffect(() => {
        if( !selectedPlayerId) {
            setAnwesenheit(0);
            setBestleistung(0);
            setRangliste(0);
            return
        }
        let player = players.filter(tmp => tmp.id === selectedPlayerId)[0];
        setAnwesenheit(player.ranglisteAnwesenheit ? player.ranglisteAnwesenheit : 0);
        setBestleistung(player.ranglisteBestleistung ? player.ranglisteBestleistung : 0);
        setRangliste(player.ranglistePunkte ? player.ranglistePunkte : 0);
    }, [selectedPlayerId]);


    const save = () => {
        setErrorMessage(undefined);
        setWorking(true);
        let url = ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/player/save/" + selectedPlayerId + "/" + anwesenheit + "/" + bestleistung + "/" + rangliste;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Erfolgreich gespeichert.");
                setWorking(false);
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setWorking(false);
            });
    }

    const saveButtonDisabled = () => {
        return !selectedPlayerId || bestleistung === undefined || anwesenheit === undefined || rangliste === undefined;
    }

    const getButtons = () => {
        return [<DHButtonSave working={working} disabled={saveButtonDisabled()} onClick={() => save()}/>];
    }

    const getModel = () => {
        let rows = [];
        for (const player of players) {
            rows.push({id: player.id, value: player.name});
        }
        return rows;
    }

    return <DHDialog onClose={onClose} showCloseButton={true} buttons={getButtons()} title="Rangliste bearbeiten" comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{marginTop: 10}}>
            <DHSelect id="id-players" label="Spieler" model={getModel()} width={300} required={true} value={selectedPlayerId} onChange={(id) => setSelectedPlayerId(id)}/>
        </div>
        <div style={{marginTop: 10}}>
            <DHNumberTextField id="id-anwesenheit" width={300} label="Anwesenheit" required={true} min={0} value={anwesenheit} onChange={(text) => setAnwesenheit(text)}/>
        </div>
        <div style={{marginTop: 10}}>
            <DHNumberTextField id="id-bestleistung" width={300} label="Bestleistung" required={true} min={0} value={bestleistung} onChange={(text) => setBestleistung(text)}/>
        </div>
        <div style={{marginTop: 10}}>
            <DHNumberTextField id="id-rangliste" width={300} label="Rangliste" required={true} min={0} value={rangliste} onChange={(text) => setRangliste(text)}/>
        </div>
    </>}/>
}
