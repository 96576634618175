import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import DHButtonSave from "../../general/DHButtonSave";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHTextField from "../../general/DHTextField";
import DHSwitch from "../../general/DHSwitch";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DHButtonNew from "../../general/DHButtonNew";

export default function LigaMannschaftVerwaltenDialog({eventOperatorId, selectedMannschaft, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [working, setWorking] = useState(false);

    const [id, setId] = useState(undefined);
    const [bezeichnung, setBezeichnung] = useState("");
    const [aktiv, setAktiv] = useState(true);

    useEffect(() => {
        if( selectedMannschaft ) {
            setId(selectedMannschaft.id);
            setBezeichnung(selectedMannschaft.bezeichnung);
            setAktiv(selectedMannschaft.aktiv);
        }
    }, [selectedMannschaft]);

    const save = () => {
        setWorking(true);
        let data = {id: id, bezeichnung: bezeichnung, aktiv: aktiv}
        let url = ConfigUtil.getConfig().resourceUrls.league + "/mannschaft/save/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setId(json.id);
                setInfoMessage("Speichern erfolgreich...");
                onRefresh();
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setWorking(false);
            });
    }

    const initDialog = () => {
        setId(undefined);
        setBezeichnung("");
        setAktiv(true);
    }

    const isDisabled = () => {
        return bezeichnung === "";
    }

    const getButtons = () => {
        return [<DHButtonSave onClick={() => save()} working={working} disabled={isDisabled()}/>,
            <DHButtonNew onClick={() => initDialog()}/>];
    }


    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-name" label="Name" width={500} required={true} value={bezeichnung} onChange={(text) => setBezeichnung(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHSwitch id="id-aktiv" label="Aktiv" value={aktiv} onChange={(value) => setAktiv(value)} required={false}/>
            </div>
        </div>
    }

    return <DHDialog onClose={() => onClose()} title="Mannschaft verwalten" show={true} showCloseButton={true}
                     buttons={getButtons()} comp={getDataArea()}/>
}
