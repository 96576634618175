import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button, TableContainer} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LigaVereinVerwaltenDialog from "./LigaVereinVerwaltenDialog";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../../general/ConfirmDialog";
import GroupsIcon from "@mui/icons-material/Groups";
import LigaVereinMannschaftenDialog from "./LigaVereinMannschaftenDialog";

export default function LigaVereinComp({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showVerwaltenDialog, setShowVerwaltenDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showMannschaftenDialog, setShowMannschaftenDialog] = useState(false);
    const [selectedVerein, setSelectedVerein] = useState(undefined);
    const [vereine, setVereine] = useState([]);
    const [filter, setFilter] = useState("");

    const CELL_PADDING_VALUE = 10;

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/vereine/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setVereine(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const deleteVerein = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/verein/delete/" + selectedVerein.id;
        FetchUtil.fetchDelete(url,
            {},
            () => {
                setInfoMessage("Löschen war erfolgreich...");
                setSelectedVerein(undefined);
                setShowDeleteDialog(false);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedVerein(undefined);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedVerein(undefined);
                setShowDeleteDialog(false);
            });
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredVereine;
        if( filter.length > 0 ) {
            filteredVereine = vereine.filter(tmp => tmp.bezeichnung.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredVereine = vereine;
        }
        filteredVereine.forEach(verein => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={verein.id}>
                <TableCell style={style}>{verein.bezeichnung}</TableCell>
                <TableCell style={style}>{verein.aktiv ? <DoneIcon/> : <BlockIcon/>}</TableCell>
                <TableCell style={style}>
                    <GroupsIcon style={{cursor:"pointer"}} onClick={() => {
                        setSelectedVerein(verein);
                        setShowMannschaftenDialog(true);
                    }}/>
                    <EditIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedVerein(verein);
                        setShowVerwaltenDialog(true);
                    }}/>
                    <DeleteIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedVerein(verein);
                        setShowDeleteDialog(true);
                    }}/>
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{marginTop: 20}}>
            Hinweis:<br/>
            Du kannst Vereine erfassen und im Anschluß einer Mannschaft einen Verein zuweisen. Eine Zuweisung eines Vereins zu einer Mannschaft ist keine Pflicht, eine Mannschaft kann auch ohne Verein
            existieren.<br/>
            Sobald mehrere Mannschaften einem Verein zugewiesen werden, kann darüber in verschiedenen Dialogen von der Mannschaft zum Verein und dann wieder zu den weiteren Mannschaften navigiert
            werden.
        </div>
        <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            <span style={{marginRight: 10}}>
                <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowVerwaltenDialog(true)}>Verein hinzufügen</Button>
            </span>
            <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
        </div>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
            <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Verein</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE, width: 50,}}>Aktiv</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE, width: 130,}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
        {showDeleteDialog && <ConfirmDialog visible={true} header={"Verein löschen"} textOben={<div>
            <div style={{color: "red", marginBottom: 20}}>Sobald du den Verein löscht, werden alle Zuordnungen des Verein, z.B. bei Mannschaften, gelöscht.</div>
            <div>Möchtest du den Verein wirklich löschen?</div>
        </div>} callBackOnNein={() => {
            setShowDeleteDialog(false);
            setSelectedVerein(undefined);
        }} callBackOnJa={() => deleteVerein()}/>}
        {showVerwaltenDialog && <LigaVereinVerwaltenDialog onRefresh={() => ladeDaten()} selectedVerein={selectedVerein} onClose={() => {
            setShowVerwaltenDialog(false);
            setSelectedVerein(undefined);
        }} eventOperatorId={eventOperatorId}/>}
        {showMannschaftenDialog && <LigaVereinMannschaftenDialog vereinId={selectedVerein.id} onClose={() => setShowMannschaftenDialog(false)}/> }
    </div>
}
