import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button, TableContainer} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LigaSpielortVerwaltenDialog from "./LigaSpielortVerwaltenDialog";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WifiIcon from '@mui/icons-material/Wifi';
import AccessibleIcon from '@mui/icons-material/Accessible';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import ConfirmDialog from "../../general/ConfirmDialog";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import GroupsIcon from "@mui/icons-material/Groups";
import LigaSpielortMannschaftenDialog from "./LigaSpielortMannschaftenDialog";


export default function LigaSpielorteComp({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showVerwaltenDialog, setShowVerwaltenDialog] = useState(false);
    const [selectedSpielort, setSelectedSpielort] = useState(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showMannschaftenDialog, setShowMannschaftenDialog] = useState(false);
    const [filter, setFilter] = useState("");

    const [spielorte, setSpielorte] = useState([]);

    const CELL_PADDING_VALUE = 10;

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spielorte/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setSpielorte(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const deleteSpielort = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spielort/delete/" + selectedSpielort.id;
        FetchUtil.fetchDelete(url,
            {},
            json => {
                setInfoMessage("Löschen war erfolgreich...");
                setSelectedSpielort(undefined);
                setShowDeleteDialog(false);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedSpielort(undefined);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedSpielort(undefined);
                setShowDeleteDialog(false);
            });
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredSpielorte;
        if( filter.length > 0 ) {
            filteredSpielorte = spielorte.filter(tmp => tmp.bezeichnung.toUpperCase().includes(filter.toUpperCase()) ||
                tmp.strasse.toUpperCase().includes(filter.toUpperCase()) ||
                tmp.plz.toUpperCase().includes(filter.toUpperCase()) ||
                tmp.ort.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredSpielorte = spielorte;
        }

        filteredSpielorte.forEach(spielort => {
            let showIcons = spielort.raucher || spielort.barrierefrei || spielort.wlan;
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={spielort.id}>
                <TableCell style={style}>{spielort.bezeichnung}{showIcons && <><br/>
                    <div>
                        {spielort.raucher &&<SmokingRoomsIcon/>}
                        {spielort.barrierefrei &&<AccessibleIcon/>}
                        {spielort.wlan &&<WifiIcon/>}
                    </div>
                </>}</TableCell>
                <TableCell style={style}>{spielort.strasse}</TableCell>
                <TableCell style={style}>{spielort.plz}</TableCell>
                <TableCell style={style}>{spielort.ort}</TableCell>
                <TableCell style={style}>{spielort.aktiv ? <DoneIcon/> : <BlockIcon/>}</TableCell>
                <TableCell style={style}>
                    <GroupsIcon style={{cursor:"pointer"}} onClick={() => {
                        setSelectedSpielort(spielort);
                        setShowMannschaftenDialog(true);
                    }}/>
                    <EditIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedSpielort(spielort);
                        setShowVerwaltenDialog(true);
                    }}/>
                    <DeleteIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedSpielort(spielort);
                        setShowDeleteDialog(true);
                    }}/>
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            <span style={{marginRight: 10}}>
                <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowVerwaltenDialog(true)}>Spielort hinzufügen</Button>
            </span>
            <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
        </div>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
            <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Bezeichnung</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Strasse</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, textAlign: "center", width: 70, padding: CELL_PADDING_VALUE}}>PLZ</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Ort</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 50,}}>Aktiv</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 130,}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>

        {showDeleteDialog && <ConfirmDialog visible={true} header={"Spielort löschen"} textOben={<div>
            <div style={{color: "red", marginBottom: 20}}>Sobald du den Spielort löscht, werden alle Zuordnungen des Spieltages, z.B. bei Mannschaften, gelöscht.</div>
            <div>Möchtest du den Spielort wirklich löschen?</div>
        </div>} callBackOnNein={() => {
            setShowDeleteDialog(false);
            setSelectedSpielort(undefined);
        }} callBackOnJa={() => deleteSpielort()}/>}
        {showVerwaltenDialog && <LigaSpielortVerwaltenDialog selectedSpielort={selectedSpielort} onRefresh={() => ladeDaten()} onClose={() => {
            setShowVerwaltenDialog(false);
            setSelectedSpielort(undefined);
        }} eventOperatorId={eventOperatorId}/>}
        {showMannschaftenDialog && <LigaSpielortMannschaftenDialog vereinId={selectedSpielort.id} onClose={() => setShowMannschaftenDialog(false)}/> }
    </div>
}
