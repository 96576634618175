import {DateField, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";

export default function DHDateField({id, label, required, value, onChange}) {

    const [myValue, setMyValue] = useState(dayjs(""));

    useEffect(() => {
        if( value === undefined || value === null || value === "") {
            setMyValue(dayjs(""));
            return;
        }
        setMyValue(dayjs(value));
    },[value]);

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>

        <DateField  id={id} label={label} variant="filled" clearable
                    slotProps={{
                        actionBar: {
                            actions: ['clear'],
                        },
                    }}
                    value={myValue} required={required} onChange={(e) => {
            if( e === null) {
                onChange(undefined);
            } else {
                let formattedValue = e.format('YYYY-MM-DD');
                if( formattedValue.toUpperCase() !== "Invalid Date".toUpperCase()) {
                    onChange(formattedValue);
                }
            }
        }
        }/>
    </LocalizationProvider>
}