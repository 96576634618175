import React, {useState} from 'react'
import {Button} from "primereact/button";
import LoginDialog from "./LoginDialog";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ConfirmKennwortDialog from "../../../general/ConfirmKennwortDialog";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import DHTextField from "../../../general/DHTextField";


export default function PublicTournamentRegistrationOptionLogin({isLoggedIn, tournamentId, passwordRequired, isUserTeilnehmer, onRefresh}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const [showKennwortDialog, setShowKennwortDialog] = useState(false);
    const [kennwort, setKennwort] = useState("");
    const [vereinsname, setVereinsname] = useState("");

    const teilnahmeAendern = () => {
        setErrorMessage(undefined);
        if( passwordRequired && kennwort === "") {
            setShowKennwortDialog(true);
            return
        }

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeAendern?tournamentid=" + tournamentId + "&kennwort=" + kennwort,
            vereinsname,
            message => {
                if( message === "") {
                    setInfoMessage("Speichern war erfolgreich!")
                    setShowKennwortDialog(false);
                    onRefresh();
                } else {
                    setErrorMessage(message);
                    setShowKennwortDialog(false);
                    setKennwort("");
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setShowKennwortDialog(false);
                setKennwort("");
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setShowKennwortDialog(false);
                setKennwort("");
            }
        );
    }

    return <div style={{marginBottom:20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {isUserTeilnehmer && <div>
            <div style={{marginBottom:10}}>Der aktuell bei Darthelfer angemeldete Benutzer ist bereits am Turnier angemeldet.</div>
            <div>
                <Button label="Nicht teilnehmen" icon="pi pi-plus" onClick={() => teilnahmeAendern()} style={{height: 40, width: 250, marginRight:10}}/>
            </div>
        </div>}
        {isLoggedIn && !isUserTeilnehmer && <div>
            <div style={{marginBottom: 10}}>Du bist in DH angemeldet</div>
            <div style={{marginTop: 10, marginBottom:10}}>
                <DHTextField id="id-vereinsname" value={vereinsname} onChange={(text) => setVereinsname(text)} required={false} label={"Vereinsname"} width={200}/>
            </div>
            <div>
                <Button label="Am Event teilnehmen" icon="pi pi-plus" onClick={() => teilnahmeAendern()} style={{height: 40, width: 250, marginRight: 10}}/>
            </div>
        </div>}
        {!isLoggedIn && !isUserTeilnehmer && <div>
            <div style={{marginBottom: 10}}>Du bist nicht in DH angemeldet</div>
            <div>
                <Button label="Login durchführen" icon="pi pi-plus" onClick={() => setShowLoginDialog(true)} style={{height: 40, width: 250, marginRight:10}}/>
            </div>
        </div>}
        {!isLoggedIn && showLoginDialog &&
            <LoginDialog onClose={() => setShowLoginDialog(false)}/>
        }
        {showKennwortDialog &&
            <ConfirmKennwortDialog visible={true}
                                   header="Turnierkennwort eingeben."
                                   textOben="Der Turnierbetreiber erwartet ein Kennwort zur Teilnahme."
                                   changeKennwort={(kennwort) => setKennwort(kennwort)}
                                   callBackOnAbbrechen={() => setShowKennwortDialog(false)}
                                   callBackOnAbsenden={() => teilnahmeAendern()}/>
        }

    </div>
}

