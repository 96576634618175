import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button, TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DateUtil from "../../../util/DateUtil";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";

export default function EventProtokollArea({tournamentId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const CELL_PADDING_VALUE = 5;

    const [liste, setListe] = useState([]);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/protokoll/" + tournamentId,
            json => {
                setListe(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            }
        );
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredListe;
        if( filter.length > 0 ) {
            filteredListe = liste.filter(tmp => tmp.beschreibung.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredListe = liste;
        }
        filteredListe.forEach(tmp => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={tmp.id}>
                <TableCell style={style}>{DateUtil.localDateTimeToDateTimeString(tmp.timestamp)}</TableCell>
                <TableCell style={style}>{tmp.beschreibung}</TableCell>
                <TableCell style={style}>{tmp.username}</TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            Die Protokollierung steht seit 06.02.2025 zur Verfügung und ist aus diesem Grund davor nicht verfügbar.
        </div>
        <div style={{paddingTop: 10, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={300} label="Filter"/>
        </div>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
            <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 200,}}>Zeitpunkt</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Text</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 150}}>Änderer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
}