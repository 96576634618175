import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ConfirmKennwortDialog from "../../../general/ConfirmKennwortDialog";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import DHTextField from "../../../general/DHTextField";


export default function PublicTournamentRegistrationOptionAnonym({tournamentId, isParticipantsMailConfirmation, isDoppelturnier, isDYP, passwordRequired, onRefresh}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [playername, setPlayername] = useState("");
    const [name, setName] = useState("");
    const [vorname, setVorname] = useState("");
    const [strasse, setStrasse] = useState("");
    const [plz, setPLZ] = useState("");
    const [ort, setOrt] = useState("");
    const [email, setEmail] = useState("");
    const [mobil, setMobil] = useState("");
    const [vereinsname, setVereinsname] = useState("");

    const [message, setMessage] = useState("");
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [showTeilnehmenButton, setShowTeilnehmenButton] = useState(true);

    const [showKennwortDialog, setShowKennwortDialog] = useState(false);
    const [kennwort, setKennwort] = useState("");

    const teilnehmen = () => {
        setErrorMessage(undefined);
        setShowInfoMessage(false);
        let message = validationMessage();
        if (message !== "") {
            return;
        }
        if (passwordRequired && kennwort === "") {
            setShowKennwortDialog(true);
            return
        }

        let data = {
            playername: playername,
            name: name,
            vorname: vorname,
            strasse: strasse,
            plz: plz,
            ort: ort,
            email: email,
            mobil: mobil,
            kennwort: kennwort,
            vereinsname: vereinsname,
            tournamentId: tournamentId
        };
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.public + "/tournamentteilnehmen", data, message => {
            if (message === "") {
                setInfoMessage("Teilnahme war erfolgreich!");
                setShowInfoMessage(true);
                setShowKennwortDialog(false);
                setKennwort("");
                setShowTeilnehmenButton(false);
                onRefresh();
            } else {
                setErrorMessage(message);
                setShowKennwortDialog(false);
                setShowInfoMessage("");
                setKennwort("");
            }
        }, responseNotOk => {
            setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            setShowKennwortDialog(false);
            setKennwort("");
        }, error => {
            setErrorMessage("Fehler beim Speichern: " + error.message);
            setShowKennwortDialog(false);
            setKennwort("");
        });
    }

    const validationMessage = () => {
        let message = "";
        if( isDoppelturnier && !isDYP && playername === "") {
            message = "Doppelname";
        }
        if (name === "") {
            message = message === "" ?"Name" : message + ", Name";
        }
        if (vorname === "") {
            message = message === "" ? "Vorname" : message + ", Vorname";
        }
        if (mobil === "") {
            message = message === "" ? "Telefon" : message + ", Telefon";
        }
        if (email === "") {
            message = message === "" ? "Email" : message + ", Email";
        }
        if (message !== "") {
            message = "Pflichtfelder füllen: " + message;
        }
        setMessage(message);
        return message;
    }

    return <div style={{marginBottom: 20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <h3>Daten eingeben und anmelden</h3>
        <div style={{marginTop:10}}>Pflichtfelder sind mit * gekennzeichnet.</div>
        {isDoppelturnier && !isDYP && <>
            <div style={{marginTop: 30}}>
                <DHTextField id="id-playername" value={playername} onChange={(text) => setPlayername(text)} required={true} label={"Name der Einzelspieler"} width={300}/>
            </div>
            <div style={{marginTop: 5, fontSize: 10}}>
                Beispiel: Max Mustermann + Maxi Mustermann
            </div>
            <div style={{marginTop: 30}}>
                Daten Ansprechpartner der Doppelpaarung
            </div>
        </>
        }
        <div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-name" value={name} onChange={(text) => setName(text)} required={true} label={"Name"} width={200}/>
            </div>
            <div style={{marginTop: 10}}>
            <DHTextField id="id-vorname" value={vorname} onChange={(text) => setVorname(text)} required={true} label={"Vorname"} width={200}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-telefon" value={mobil} onChange={(text) => setMobil(text)} required={true} label={"Mobil"} width={200}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-email" value={email} onChange={(text) => setEmail(text)} required={true} label={"Email"} width={200}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-strasse" value={strasse} onChange={(text) => setStrasse(text)} required={false} label={"Strasse"} width={200}/>
            </div>
            <div style={{marginTop: 10}}>
                <span style={{marginRight: 5}}>
                    <DHTextField id="id-plz" value={strasse} onChange={(text) => setPLZ(text)} required={false} label={"PLZ"} width={100}/>
                </span>
                <DHTextField id="id-ort" value={ort} onChange={(text) => setOrt(text)} required={false} label={"Ort"} width={200}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-vereinsname" value={vereinsname} onChange={(text) => setVereinsname(text)} required={false} label={"Vereinsname"} width={200}/>
            </div>
            {message !== "" && <div style={{marginTop: 30, display: "flex", color: "red"}}>
                {message}
            </div>}
            {showInfoMessage && isParticipantsMailConfirmation !== null && isParticipantsMailConfirmation && <div style={{marginTop: 30, color: "green", fontWeight: "bold", fontSize: 18}}>
                Du erhälst umgehend eine Email. Bitte bestätige den Bestätigungslink in der Email.
                <br/><br/>
                ACHTUNG! Prüfe bitte deinen SPAM-Ordner.
                <br/><br/>
                Die Mail ist 24 Stunden gültig. Sofern du die Teilnahme innerhalb dieser Zeit nicht bestätigst, wirst du
                vom System automatisch wieder abgemeldet.
            </div>}
            {showInfoMessage && (isParticipantsMailConfirmation === null || !isParticipantsMailConfirmation) && <div style={{marginTop: 30, color: "green", fontWeight: "bold", fontSize: 18}}>
                Deine Anmeldung war erfolgreich.
            </div>}
            {showTeilnehmenButton && <div style={{marginTop: 30, display: "flex"}}>
                <Button label="Anmelden" icon="pi pi-plus" onClick={() => teilnehmen()}
                        style={{height: 40, width: 350}}/>
            </div>}
            {showKennwortDialog && <ConfirmKennwortDialog visible={true}
                                                          header="Turnierkennwort eingeben."
                                                          textOben="Der Turnierbetreiber erwartet ein Kennwort zur Teilnahme."
                                                          changeKennwort={(kennwort) => setKennwort(kennwort)}
                                                          callBackOnAbbrechen={() => setShowKennwortDialog(false)}
                                                          callBackOnAbsenden={() => teilnehmen()}/>}

        </div>
    </div>
}

