import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Badge, Button, TableContainer} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LigaMannschaftVerwaltenDialog from "./LigaMannschaftVerwaltenDialog";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import ConfirmDialog from "../../general/ConfirmDialog";
import LigaVereinAuswahlDialog from "./LigaVereinAuswahlDialog";
import LigaSpielortAuswahlDialog from "./LigaSpielortAuswahlDialog";
import LigaSpielerAuswahlDialog from "./LigaSpielerAuswahlDialog";

export default function LigaMannschaftComp({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showVerwaltenDialog, setShowVerwaltenDialog] = useState(false);
    const [selectedMannschaft, setSelectedMannschaft] = useState(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showSpielerAuswahlDialog, setShowSpielerAuswahlDialog] = useState(false);
    const [showVereinAuswahlDialog, setShowVereinAuswahlDialog] = useState(false);
    const [showSpielortAuswahlDialog, setShowSpielortAuswahlDialog] = useState(false);
    const [filter, setFilter] = useState("");

    const [mannschaften, setMannschaften] = useState([]);

    const CELL_PADDING_VALUE = 10;

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/mannschaften/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setMannschaften(json);
                if( selectedMannschaft !== undefined) {
                    setSelectedMannschaft(json.filter(tmp => tmp.id === selectedMannschaft.id)[0]);
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const zuweisenSpielortId = (spielortId) => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/mannschaft/zuweisen/spielort/" + selectedMannschaft.id + "/" + spielortId;
        FetchUtil.fetchPost(url,
            {},
            () => {
                setInfoMessage("Zuweisung erfolgreich...");
                setSelectedMannschaft(undefined);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedMannschaft(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedMannschaft(undefined);
            });
    }

    const zuweisenVereinId = (vereinId) => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/mannschaft/zuweisen/verein/" + selectedMannschaft.id + "/" + vereinId;
        FetchUtil.fetchPost(url,
            {},
            () => {
                setInfoMessage("Zuweisung erfolgreich...");
                setSelectedMannschaft(undefined);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedMannschaft(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedMannschaft(undefined);
            });
    }

    const deleteMannschaft = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/mannschaft/delete/" + selectedMannschaft.id;
        FetchUtil.fetchDelete(url,
            {},
            () => {
                setInfoMessage("Löschen war erfolgreich...");
                setSelectedMannschaft(undefined);
                setShowDeleteDialog(false);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedMannschaft(undefined);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedMannschaft(undefined);
                setShowDeleteDialog(false);
            });
    }


    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredMannschaften;
        if( filter.length > 0 ) {
            filteredMannschaften = mannschaften.filter(tmp => tmp.bezeichnung.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredMannschaften = mannschaften;
        }
        filteredMannschaften.forEach(mannschaft => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={mannschaft.id}>
                <TableCell style={style}>{mannschaft.bezeichnung}
                    <br/>
                    <div style={{marginTop:10}}>
                        <Badge badgeContent={mannschaft.anzahlSpieler} color="primary" style={{marginRight:20, cursor:"pointer"}}
                               onClick={() => {
                                   setSelectedMannschaft(mannschaft);
                                   setShowSpielerAuswahlDialog(true);
                               }}>
                            <PersonIcon />
                        </Badge>
                        <Badge variant="dot" color="success" style={{marginRight:20, cursor:"pointer"}} invisible={!mannschaft.ligaVereinId}
                                    onClick={() => {
                                        setSelectedMannschaft(mannschaft);
                                        setShowVereinAuswahlDialog(true);
                                    }}>
                            <AccountBalanceIcon style={{marginRight:2}}/>
                        </Badge>
                        <Badge variant="dot" color="success" style={{marginRight:20, cursor:"pointer"}} invisible={!mannschaft.ligaSpielortId}
                               onClick={() => {
                                   setSelectedMannschaft(mannschaft);
                                   setShowSpielortAuswahlDialog(true);
                               }}>
                            <LocationCityIcon/>
                        </Badge>
                    </div>
                </TableCell>
                <TableCell style={style}>{mannschaft.aktiv ? <DoneIcon/> : <BlockIcon/>}</TableCell>
                <TableCell style={style}>
                    <CalendarMonthIcon style={{cursor:"pointer"}}/>
                    <EditIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedMannschaft(mannschaft);
                        setShowVerwaltenDialog(true);
                    }}/>
                    <DeleteIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedMannschaft(mannschaft);
                        setShowDeleteDialog(true);
                    }}/>
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }


    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            <span style={{marginRight: 10}}>
                <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowVerwaltenDialog(true)}>Mannschaft hinzufügen</Button>
            </span>
            <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
        </div>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
            <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Mannschaft</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE, width: 50,}}>Aktiv</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE, width: 130,}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
        {showDeleteDialog && <ConfirmDialog visible={true} header={"Mannschaft löschen"} textOben={<div>
            <div style={{color: "red", marginBottom: 20}}>Du kannst die Mannschaft nur löschen, sofern diese noch in keiner Liga aktiv ist. Wenn gewünscht, dann deaktivieren.</div>
            <div>Möchtest du die Mannschaft wirklich löschen?</div>
        </div>} callBackOnNein={() => {
            setShowDeleteDialog(false);
            setSelectedMannschaft(undefined);
        }} callBackOnJa={() => deleteMannschaft()}/>}
        {showVerwaltenDialog && <LigaMannschaftVerwaltenDialog onClose={() => {
            setShowVerwaltenDialog(false);
            setSelectedMannschaft(undefined);
        }} onRefresh={() => ladeDaten()} selectedMannschaft={selectedMannschaft} eventOperatorId={eventOperatorId}/>}
        {showVereinAuswahlDialog && <LigaVereinAuswahlDialog onRefresh={() => ladeDaten()} onClose={() => {
            setShowVereinAuswahlDialog(false);
            setSelectedMannschaft(undefined);
        }} eventOperatorId={eventOperatorId} selectedVereinId={selectedMannschaft ? selectedMannschaft.ligaVereinId : undefined}
        onAuswahl={(vereinId) => zuweisenVereinId(vereinId)}/> }
        {showSpielortAuswahlDialog && <LigaSpielortAuswahlDialog onRefresh={() => ladeDaten()} onClose={() => {
            setShowSpielortAuswahlDialog(false);
            setSelectedMannschaft(undefined);
        }} eventOperatorId={eventOperatorId} selectedSpielortId={selectedMannschaft ? selectedMannschaft.ligaSpielortId : undefined}
                                                             onAuswahl={(spielortId) => zuweisenSpielortId(spielortId)}/> }
        {showSpielerAuswahlDialog && <LigaSpielerAuswahlDialog onRefresh={() => ladeDaten()} onClose={() => {
            setShowSpielerAuswahlDialog(false);
            setSelectedMannschaft(undefined);
        }} eventOperatorId={eventOperatorId} mannschaftId={selectedMannschaft ? selectedMannschaft.id : undefined}/> }
    </div>
}
