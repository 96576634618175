import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import DHButtonSave from "../../general/DHButtonSave";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHTextField from "../../general/DHTextField";
import DHSwitch from "../../general/DHSwitch";
import DHButtonNew from "../../general/DHButtonNew";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DHDateField from "../../general/DHDateField";

export default function LigaSpielerVerwaltenDialog({eventOperatorId, selectedSpieler, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [working, setWorking] = useState(false);

    const [id, setId] = useState(undefined);
    const [spielernummer, setSpielernummer] = useState("");
    const [name, setName] = useState("");
    const [vorname, setVorname] = useState("");
    const [strasse, setStrasse] = useState("");
    const [plz, setPLZ] = useState("");
    const [ort, setOrt] = useState("");
    const [telefon, setTelefon] = useState("");
    const [mobil, setMobil] = useState("");
    const [email, setEmail] = useState("");
    const [geburtstag, setGeburtstag] = useState(undefined);
    const [aktiv, setAktiv] = useState(true);

    useEffect(() => {
        if( selectedSpieler ) {
            setId(selectedSpieler.id);
            setSpielernummer(selectedSpieler.spielernummer);
            setName(selectedSpieler.name);
            setVorname(selectedSpieler.vorname);
            setStrasse(selectedSpieler.strasse);
            setPLZ(selectedSpieler.plz);
            setOrt(selectedSpieler.ort);
            setTelefon(selectedSpieler.telefon);
            setMobil(selectedSpieler.mobil);
            setEmail(selectedSpieler.email);
            setGeburtstag(selectedSpieler.geburtstag);
            setAktiv(selectedSpieler.aktiv);
        }
    }, [selectedSpieler]);

    const save = () => {
        setWorking(true);
        let data = {id: id, spielernummer: spielernummer, name: name, vorname: vorname, strasse: strasse, plz: plz, ort: ort, telefon: telefon,
            mobil: mobil, email:email, geburtstag: geburtstag, aktiv: aktiv}
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spieler/save/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setId(json.id);
                setInfoMessage("Speichern erfolgreich...");
                onRefresh();
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setWorking(false);
            });
    }

    const initDialog = () => {
        setId(undefined);
        setSpielernummer("");
        setName("");
        setVorname("");
        setStrasse("");
        setPLZ("");
        setOrt("");
        setTelefon("");
        setMobil("");
        setEmail("");
        setGeburtstag("");
        setAktiv(true);
    }

    const isDisabled = () => {
        return spielernummer === "" || name === "" || vorname === "";
    }

    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div>
                <DHTextField id="id-spielernummer" label="Spielernummer" width={500} required={true} value={spielernummer} onChange={(text) => setSpielernummer(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-name" label="Name" width={500} required={true} value={name} onChange={(text) => setName(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-vorname" label="Vorname" width={500} required={true} value={vorname} onChange={(text) => setVorname(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-strasse" label="Strasse" width={500} required={false} value={strasse} onChange={(text) => setStrasse(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-plz" label="PLZ" width={100} required={false} value={plz} onChange={(text) => setPLZ(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-ort" label="Ort" width={500} required={false} value={ort} onChange={(text) => setOrt(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-telefon" label="Telefon" width={500} required={false} value={telefon} onChange={(text) => setTelefon(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-mobil" label="Mobil" width={500} required={false} value={mobil} onChange={(text) => setMobil(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-email" label="Email" width={500} required={false} value={email} onChange={(text) => setEmail(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHDateField id="id-geburtstag" label="Geburtstag" value={geburtstag} required={false} onChange={(value) => {
                    setGeburtstag(value);
                }}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHSwitch id="id-aktiv" label="Aktiv" value={aktiv} onChange={(value) => setAktiv(value)} required={false}/>
            </div>
        </div>
    }


    const getButtons = () => {
        return [<DHButtonSave onClick={() => save()} working={working} disabled={isDisabled()}/>,
            <DHButtonNew onClick={() => initDialog()}/>];
    }

    return <DHDialog onClose={() => onClose()} title="Spieler verwalten" show={true} showCloseButton={true}
                     buttons={getButtons()} comp={getDataArea()}/>
}
