import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";

export default function LigaSpielortMannschaftenDialog({vereinId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [mannschaften, setMannschaften] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, [vereinId]);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/mannschaften/spielort/" + vereinId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setMannschaften(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const getDataArea = () => {
        let rows = [];
        if( mannschaften.length > 0 ) {
            for (const mannschaft of mannschaften) {
                rows.push(<div key={"key_id_mannschaft_" + mannschaft.id } style={{marginBottom:20, fontSize:16}}>{mannschaft.bezeichnung}</div>)
            }
        } else {
            rows.push(<div key={"key-id-empty-row"} style={{marginBottom:20, fontSize:16}}>Keine Mannschaften vorhanden</div>)
        }
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            {rows}
        </div>
    }
    return <DHDialog onClose={() => onClose()} title="Mannschaften" showCloseButton={true} comp={<>{getDataArea()}</>}/>
}
