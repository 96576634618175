import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button, TableContainer} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LigaSpielerVerwaltenDialog from "./LigaSpielerVerwaltenDialog";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../../general/ConfirmDialog";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";

export default function LigaSpielerComp({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showVerwaltenDialog, setShowVerwaltenDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedSpieler, setSelectedSpieler] = useState(undefined);
    const [spieler, setSpieler] = useState([]);
    const [filter, setFilter] = useState("");

    const CELL_PADDING_VALUE = 10;

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spieler/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setSpieler(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const deleteSpieler = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spieler/delete/" + selectedSpieler.id;
        FetchUtil.fetchDelete(url,
            {},
            json => {
                setInfoMessage("Löschen war erfolgreich...");
                setSelectedSpieler(undefined);
                setShowDeleteDialog(false);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedSpieler(undefined);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedSpieler(undefined);
                setShowDeleteDialog(false);
            });
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredSpieler;
        if( filter.length > 0 ) {
            filteredSpieler = spieler.filter(tmp => tmp.spielernummer.toUpperCase().includes(filter.toUpperCase()) ||
                tmp.name.toUpperCase().includes(filter.toUpperCase()) ||
                tmp.vorname.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredSpieler = spieler;
        }
        filteredSpieler.forEach(tmpSpieler => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={tmpSpieler.id}>
                <TableCell style={style}>{tmpSpieler.spielernummer}</TableCell>
                <TableCell style={style}>{tmpSpieler.name}</TableCell>
                <TableCell style={style}>{tmpSpieler.vorname}</TableCell>
                <TableCell style={style}>{tmpSpieler.aktiv ? <DoneIcon/> : <BlockIcon/>}</TableCell>
                <TableCell style={style}>
                    <EditIcon style={{cursor:"pointer"}} onClick={() => {
                        setSelectedSpieler(tmpSpieler);
                        setShowVerwaltenDialog(true);
                    }}/>
                    <DeleteIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedSpieler(tmpSpieler);
                        setShowDeleteDialog(true);
                    }}/>
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{paddingTop:20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            <span style={{marginRight:10}}>
                <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowVerwaltenDialog(true)}>Spieler hinzufügen</Button>
            </span>
            <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
        </div>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop:20}}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", padding:0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE}}>Nummer</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE}}>Name</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE}}>Vorname</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE, width: 50,}}>Aktiv</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE, width: 100,}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
        {showDeleteDialog && <ConfirmDialog visible={true} header={"Spieler löschen"} textOben="Möchtest du den Spieler wirklich löschen"
                                            callBackOnNein={() => {
            setShowDeleteDialog(false);
            setSelectedSpieler(undefined);
        }} callBackOnJa={() => deleteSpieler()}/>}
        {showVerwaltenDialog && <LigaSpielerVerwaltenDialog onClose={() => {
            setShowVerwaltenDialog(false);
            setSelectedSpieler(undefined);
        }} onRefresh={() => ladeDaten()}
                                                            selectedSpieler={selectedSpieler}
                                                            eventOperatorId={eventOperatorId}/> }
    </div>
}
